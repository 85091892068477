import {
  LoginDetaljeEntryType,
  LoginDetalje,
  LoginHistorikDetaljer,
  LoginDetaljeResponse
} from "@/types/loginHistorik";
import { dateStringToDateWithTime } from "@/util/date";
import { StatusCode } from "@/types/statuscode";

export class LoginDetaljer implements LoginHistorikDetaljer {

  statusCode: StatusCode;
  queryDate: string;
  loginDetaljer: LoginDetaljeEntry[];
  clientUuid: string;
  tjenesteNavn: string;

  constructor(detaljeEntry: LoginDetaljeResponse, clientUuid: string, tjenesteNavn: string) {
    this.statusCode = detaljeEntry.statusCode;
    this.queryDate = detaljeEntry.queryDate;
    this.loginDetaljer = detaljeEntry.aktoerLoginHistorik.map(entry => new LoginDetaljeEntry(entry));
    this.clientUuid = clientUuid;
    this.tjenesteNavn = tjenesteNavn;
  }

}

class LoginDetaljeEntry implements LoginDetalje {
  idpName: string;
  latestLogin: string;

  constructor(loginDetaljeEntry: LoginDetaljeEntryType) {
    this.idpName = loginDetaljeEntry.idpName;
    this.latestLogin = loginDetaljeEntry.latestLogin;
  }

  getFormattedDateTime() {
    return dateStringToDateWithTime(this.latestLogin);
  }

}


