export const replacePathParamsInUrl = (path: string, params: { [key: string]: string }) => {
  Object.keys(params).forEach((key) => {
    path = path.replace(`{${key}}`, encodeURIComponent(params[key]));
  });
  return path;
};

export const replaceRouterParams = (path: string, params: { [key: string]: string }) => {
  Object.keys(params).forEach((key) => {
    path = path.replace(`:${key}`, encodeURIComponent(params[key]));
  });
  return path;
};
