import { ComponentType, lazy, ReactNode, Suspense } from "react";

export default function LazyComponent<T>({ Component, promise, additionalProps, children }: {
  Component: ComponentType<T>,
  promise: Promise<Partial<T>>,
  additionalProps?: Partial<T>,
  children: ReactNode,
}) {
  const LazyComp = lazy(
    () =>
      promise.then((result: T) => {
        return {
          default: () => <Component {...result} {...additionalProps} />
        };
      })
  );
  return <Suspense fallback={children}>
    <LazyComp />
  </Suspense>;
}
