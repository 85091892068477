import { useParams } from "react-router";
import { useAktoerKontakt } from "@/hooks/useAktoerKontakt";
import { UserContext } from "@/contexts/userContext";
import { useContext } from "react";
import SkiftBrugernavn from "@/components/skiftBrugernavn/SkiftBrugernavn";
import SkiftBrugernavnStepup from "@/components/skiftBrugernavn/SkiftBrugernavnStepup";
import SkiftBrugernavnKanIkke from "@/components/skiftBrugernavn/SkiftBrugernavnKanIkke";
import SkiftBrugernavnNotActivated from "@/components/skiftBrugernavn/SkiftBrugernavnNotActivated";

export default function SkiftBrugernavnLoader() {
  const { aktoerOverblik, isToFaktor } = useContext(UserContext);
  const { aktoerId } = useParams();
  const { getCurrentKontakt } = useAktoerKontakt(aktoerOverblik);

  const componentResolver = () => {
    const currentKontakt = getCurrentKontakt(aktoerId);
    if (!isToFaktor) {
      return <SkiftBrugernavnStepup kontakt={currentKontakt} />;
    }

    if (!currentKontakt.canChangeUsername) {
      return <SkiftBrugernavnKanIkke kontakt={currentKontakt} />;
    }

    if (!currentKontakt.userActivated) {
      return <SkiftBrugernavnNotActivated kontakt={currentKontakt} />;
    }

    return <SkiftBrugernavn kontakt={currentKontakt} />;
  };

  if (aktoerId) {
    return componentResolver();
  }
  return <></>;
}
