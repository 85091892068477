import { LoginHistorikDetaljer } from "@/types/loginHistorik";
import useSearch from "@/hooks/useSearch";
import usePagination from "@/hooks/usePagination";
import { DEFAULT_PAGINATION_AMOUNT } from "@/constants/pagination";
import SearchField from "@/components/search/search";
import LoginDetaljeTable from "@/components/loginhistorik/logindetaljer/LoginDetaljeTable";
import Pagination from "@/components/pagination/Pagination";
import { useState } from "react";

export default function LoginDetaljeVisning(props: LoginHistorikDetaljer) {
  const [paginationKey, setPaginationKey] = useState(Date.now());
  const {
    searchResult,
    performSearch
  } = useSearch(props.loginDetaljer, ["idpName", "getFormattedDateTime"], { global: true });
  const {
    currentData,
    nextPage,
    previousPage,
    getNumberOfPages,
    currentPage,
    goToPage,
    totalEntries,
    setAmountToShow,
    resetPagination,
    setCurrentPage,
  } = usePagination(searchResult, 1, DEFAULT_PAGINATION_AMOUNT);
  const onSearch = (searchTerm: string) => {
    performSearch(searchTerm);
    resetPagination();
    setPaginationKey(Date.now());
  };
  const onUpdateAmount = (amount: number) => {
    setAmountToShow(amount);
    setCurrentPage(1);
  };
  return <>

    <div className={"d-flex justify-content-between align-items-end mb-4"}>
      <h3>Logins for {props.tjenesteNavn}</h3>
      <SearchField onSearch={onSearch} />
    </div>
    <LoginDetaljeTable logins={currentData} queryDate={props.queryDate} />
    <Pagination
      key={paginationKey}
      currentPage={currentPage}
      numberOfPages={getNumberOfPages}
      totalEntries={totalEntries}
      onGoToPage={goToPage}
      onNextPage={nextPage}
      onPreviousPage={previousPage}
      onSelectAmount={onUpdateAmount}
    />
  </>;
}
