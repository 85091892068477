import PaginationAmountSelector from "./PaginationAmountSelector";
import PaginationButtons from "./PaginationButtons";
import PaginationNavButtons from "./PaginationNavButtons";

type PaginationType = {
  numberOfPages: number;
  currentPage: number;
  totalEntries: number;
  onNextPage: () => void;
  onPreviousPage: () => void;
  onGoToPage: (page: number) => void;
  onSelectAmount: (amount: number) => void;
};

export default function Pagination({
                                     numberOfPages,
                                     currentPage,
                                     totalEntries,
                                     onNextPage,
                                     onPreviousPage,
                                     onGoToPage,
                                     onSelectAmount,
                                   }: PaginationType) {
  return (
    <nav className="pagination" aria-label={"Paginaering med " + numberOfPages + " sider"}>
      <PaginationNavButtons
        showPrevious={currentPage !== 1}
        showNext={currentPage !== numberOfPages}
        onNextPage={onNextPage}
        onPreviousPage={onPreviousPage}
      >
        <>
          <span className="pagination-mobile">
            Side {currentPage} af {numberOfPages}
          </span>
          <ul className="pagination__items">
            <PaginationButtons currentPage={currentPage} numberOfPages={numberOfPages} onGoToPage={onGoToPage} />
          </ul>
        </>
      </PaginationNavButtons>

      <PaginationAmountSelector onSelectAmount={onSelectAmount} label="Vælg antal logins" totalEntries={totalEntries} />
    </nav>
  );
}
