import { UserInfo } from "@/types/userinfo";
import UserView from "../UserView";

export default function PortalInfoMobile({ userinfo }: { readonly userinfo: UserInfo }) {
  return (
    <div className="portal-info-mobile">
      <h3 className="sr-only">Bruger</h3>
      {userinfo.isLoggedIn && <UserView userinfo={userinfo} />}
    </div>
  );
}
