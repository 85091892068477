import SkiftBrugernavnBase from "@/components/skiftBrugernavn/SkiftBrugernavnBase";
import StatusBesked from "@/components/besked/StatusBesked";
import { Kontakt } from "@/types/kontakt";

type SkiftBrugernavnKanIkkeType = {
  kontakt: Kontakt;
}

export default function SkiftBrugernavnKanIkke({ kontakt }: SkiftBrugernavnKanIkkeType) {
  return <SkiftBrugernavnBase onClick={() => null} skiftBrugernavnDisabled={true} kontakt={kontakt}>
    <StatusBesked type={"info"}
                  overskrift={"Kan ikke skifte brugernavn"}>
      Det er ikke muligt at skrive brugernavn for {kontakt.fornavn}. Kontakt supporten, hvis du ønsker at skifte brugernavn.
    </StatusBesked>
  </SkiftBrugernavnBase>;
};
