import eleverDashboard from "@/assets/elever-dashboard.jpeg";
import LoginButton from "@/components/buttons/LoginButton";
import ImageRightCard from "@/components/cards/ImageRightCard";

export default function LoginScreen() {
  return (
    <ImageRightCard imageAssetUrl={eleverDashboard} altText={"Elever der koncentrerer sig"}>
      <LoginButton />
    </ImageRightCard>
  );
}
