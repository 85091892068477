import { UserInfo } from "@/types/userinfo";
import { defaultFeatureToggles } from "@/test/featureTogglesFixtures";

export const EMPTY_USERINFO: UserInfo = {
  aktoerOverblik: { aktoerer: [], navn: "", statusCode: "" },
  fullName: "",
  isLoggedIn: false,
  isToFaktor: false,
  ...defaultFeatureToggles,
};
