import { AktoerDto } from "@/types/aktoerDto";
import { getAktoerKontaktFullName } from "@/util/aktoer";

export const sortAktoerKontaktAlphabetically = (aktoerer: AktoerDto[]) => {
  return aktoerer.sort(sortKontaktAlphabetically);
};

const sortKontaktAlphabetically = (aktoerA: AktoerDto, aktoerB: AktoerDto) => {
  if (getAktoerKontaktFullName(aktoerA) < getAktoerKontaktFullName(aktoerB)) {
    return -1;
  }
  if (getAktoerKontaktFullName(aktoerA) > getAktoerKontaktFullName(aktoerB)) {
    return 1;
  }
  return 0;
};

export const uniqueByProperty = <T, K extends keyof T>(objects: T[], prop: K): T[] => {
  const seen = new Set<T[K]>();
  return objects.reduce((acc: T[], object) => {
    const property = object[prop];
    if (!seen.has(property)) {
      seen.add(property);
      acc.push(object);
    }
    return acc;
  }, []);
};
