import { Outlet, useNavigate, useParams } from "react-router";
import { ChangeEvent, useContext, useEffect } from "react";
import { PATHS } from "@/constants/paths";
import { useAktoerKontakt } from "@/hooks/useAktoerKontakt";
import { UserContext } from "@/contexts/userContext";
import { UserInfo } from "@/types/userinfo";

export default function SkiftBrugernavnView() {
  const userinfo = useContext<UserInfo>(UserContext);
  const { aktoerId } = useParams();
  const { primaerKontaktAktoers } = useAktoerKontakt(userinfo.aktoerOverblik);
  const navigate = useNavigate();
  useEffect(() => {
    if (!aktoerId) {
      navigate(PATHS.SKIFT_BRUGERNAVN + "/" + primaerKontaktAktoers[0].aktoerUuid);
    } else {
      navigate(PATHS.SKIFT_BRUGERNAVN + "/" + aktoerId);
    }
  }, [aktoerId]);
  const navigateToChild = ({ currentTarget }: ChangeEvent<HTMLSelectElement>) => {
    navigate(PATHS.SKIFT_BRUGERNAVN + "/" + currentTarget.value);
  };
  return <>
    <h1>Skift brugernavn</h1>
    <label htmlFor={"skiftbrugernavn_select"}>Vælg barn</label>
    <select defaultValue={aktoerId} className={"form-select"} id={"skiftbrugernavn_select"} onChange={navigateToChild}>
      {
        primaerKontaktAktoers.map(kontakt =>
          <option key={kontakt.aktoerUuid} value={kontakt.aktoerUuid}>
            {kontakt.fornavn}
          </option>)
      }
    </select>
    <p>Du kan skifte brugernavn for dine børn og dem, du er kontakt til. Tryk på 'Skift brugernavn'
      for det barn, du ønsker
      at give et nyt brugernavn.</p>
    <Outlet />
  </>;
}
