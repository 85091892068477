import { UserInfo } from "@/types/userinfo";
import { API_PATHS } from "@/constants/paths";
import baseFetch from "@/services/baseService";
import { UpdateUsernameDto, UpdateUsernameRequest } from "@/types/updateUserinfo";

export async function getUserInfo(): Promise<UserInfo> {
  const userinfo = await baseFetch<UserInfo>(API_PATHS.userinfo);
  return userInfoMapper(userinfo);
}

export async function updateUsername(aktoerId: string): Promise<UpdateUsernameDto> {
  const request: UpdateUsernameRequest = { aktoerId };
  return baseFetch<UpdateUsernameDto>(API_PATHS.updateUsername, {
    body: JSON.stringify(request),
    method: "POST",
  });
}

const userInfoMapper = (userinfo: UserInfo) => {
  const userIsKontaktOrForaelder = userinfo.aktoerOverblik.aktoerer.some((aktoer) => aktoer.aktoerType === "Forælder eller kontaktperson");
  userinfo.featureToggles.skiftBrugernavn &&= userIsKontaktOrForaelder;
  return userinfo;
};
