import baseFetch from "@/services/baseService";
import { LoginDetaljeResponse, LoginHistorik } from "@/types/loginHistorik";
import { API_PATHS } from "@/constants/paths";
import { replacePathParamsInUrl } from "@/util/url";

export const getUserLoginHistory = async () => {
  return await baseFetch<LoginHistorik>(API_PATHS.getUserLoginHistory);
};

export const getUserLoginHistoryDetails = async (clientUuid: string) => {
  const path = replacePathParamsInUrl(API_PATHS.getUserLoginDetails, { clientUuid });
  return await baseFetch<LoginDetaljeResponse>(path);
};

export const getKontaktLoginHistory = async (aktoerId: string) => {
  const path = replacePathParamsInUrl(API_PATHS.getKontaktLoginHistory, { aktoerId });
  return await baseFetch<LoginHistorik>(path);
};

export const getKontaktLoginHistoryDetails = async (aktoerId: string, clientUuid: string) => {
  const path = replacePathParamsInUrl(API_PATHS.getKontaktLoginDetails, { aktoerId, clientUuid });
  return await baseFetch<LoginDetaljeResponse>(path);
};

