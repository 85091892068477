import "../../assets/scss/index.scss";
import React from "react";
import { RouterProvider } from "react-router";
import getRouter from "@/router/router";
import IconCollection from "@/components/icons/IconCollection";
import { init } from "dkfds";

export default function App() {
  init();
  return (
    <>
      <IconCollection />
      <RouterProvider router={getRouter()} />
    </>
  );
}
