export default function LoginHistorikTableHeader() {
  return (
    <thead>
    <tr>
      <th scope={"col"}>Tjeneste</th>
      <th scope={"col"}>Login</th>
      <th scope={"col"}>Antal logins</th>
      <th scope={"col"}>Seneste login</th>
      <th></th>
    </tr>
    </thead>
  );
}
