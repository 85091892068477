export const getTokenFromCookie = (token: string) => {
  if (!token) {
    return null;
  }
  const foundToken = document.cookie.split("; ").find((cookieToken) => {
      return cookieToken.startsWith(token);
    }
  );
  return foundToken?.substring(token.length, foundToken.length) ?? null;

};
