import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { ReactNode } from "react";

export default function LoginHistorikSkeletonLoader({ children, amountOfColumns }: {
  children: ReactNode,
  amountOfColumns: number
}) {
  const columns = Array.from(Array(amountOfColumns).keys());
  return (
    <table className="table table--compact table--zebra">
      {children}
      <tbody>
      {[1, 2, 3, 4].map((value) => {
        return (
          <tr key={"skeletonrow" + value}>
            {columns.map((td) => (<td key={"skeletontd" + value + td}><Skeleton /></td>))}
          </tr>
        );
      })}
      </tbody>
    </table>
  );
}
