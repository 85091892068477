import PageButton from "./PageButton";

type PaginationType = {
  numberOfPages: number;
  currentPage: number;
  onGoToPage: (page: number) => void;
};

export default function PaginationButtons({ numberOfPages, currentPage, onGoToPage }: PaginationType) {
  const isActivePage = (page: number) => (currentPage === page ? " current-page" : "");

  const showStartElipsis = (): boolean => currentPage > 3;

  const showEndElipsis = (): boolean => currentPage < numberOfPages - 3;

  if (numberOfPages <= 7) {
    return Array.from(Array(numberOfPages + 1).keys())
      .slice(1, numberOfPages + 1)
      .map((page) => <PageButton page={page} onGoToPage={onGoToPage} isActivePage={isActivePage(page)} key={"pagemap" + page}/>);
  }

  if (showEndElipsis() && showStartElipsis()) {
    return (
      <>
        <PageButton page={1} onGoToPage={onGoToPage} isActivePage={isActivePage(1)} />
        <span>...</span>
        <PageButton page={currentPage - 1} onGoToPage={onGoToPage} isActivePage={isActivePage(currentPage - 1)} />
        <PageButton page={currentPage} onGoToPage={onGoToPage} isActivePage={isActivePage(currentPage)} />
        <PageButton page={currentPage + 1} onGoToPage={onGoToPage} isActivePage={isActivePage(currentPage + 1)} />
        <span>...</span>
        <PageButton page={numberOfPages} onGoToPage={onGoToPage} isActivePage={isActivePage(numberOfPages)} />
      </>
    );
  }

  if (showStartElipsis()) {
    return (
      <>
        <PageButton page={1} onGoToPage={onGoToPage} isActivePage={isActivePage(1)} />
        <span>...</span>
        <PageButton page={currentPage - 1} onGoToPage={onGoToPage} isActivePage={isActivePage(currentPage - 1)} />
        <PageButton page={currentPage} onGoToPage={onGoToPage} isActivePage={isActivePage(currentPage)} />
        {currentPage + 1 <= numberOfPages && (
          <PageButton page={currentPage + 1} onGoToPage={onGoToPage} isActivePage={isActivePage(currentPage + 1)} />
        )}
        {currentPage + 2 <= numberOfPages && (
          <PageButton page={currentPage + 2} onGoToPage={onGoToPage} isActivePage={isActivePage(currentPage + 2)} />
        )}
        {currentPage + 3 === numberOfPages && (
          <PageButton page={numberOfPages} onGoToPage={onGoToPage} isActivePage={isActivePage(currentPage + 3)} />
        )}
      </>
    );
  }

  if (showEndElipsis()) {
    return (
      <>
        <PageButton page={1} onGoToPage={onGoToPage} isActivePage={isActivePage(1)} />
        <PageButton page={2} onGoToPage={onGoToPage} isActivePage={isActivePage(2)} />
        <PageButton page={3} onGoToPage={onGoToPage} isActivePage={isActivePage(3)} />
        <span>...</span>
        <PageButton page={numberOfPages} onGoToPage={onGoToPage} isActivePage={isActivePage(numberOfPages)} />
      </>
    );
  }
}
