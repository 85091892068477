import { create } from "zustand";
import { LoginHistorik, LoginHistorikDetaljer } from "@/types/loginHistorik";
import { getKontaktLoginHistory, getKontaktLoginHistoryDetails } from "@/services/LoginHistoryService";
import { LoginDetaljer } from "@/model/loginDetalje";

export type StoreLoginHistorik = (LoginHistorik & { aktoerId: string })
export type StoreAktoerLoginHistorikDetalje = (LoginHistorikDetaljer & { aktoerId: string })

type AktoerLoginHistorikStore = {
  aktoerLogins: StoreLoginHistorik[],
  aktoerLoginDetaljer: StoreAktoerLoginHistorikDetalje[],

  getAktoerLogin: (aktoerId: string) => Promise<StoreLoginHistorik>
  setAktoerLogins: (aktoerLogins: StoreLoginHistorik) => void,

  getAktoerLoginDetalje: (aktoerId: string, clientUuid: string) => Promise<StoreAktoerLoginHistorikDetalje>,
  setAktoerLoginDetalje: (aktoerLoginDetalje: StoreAktoerLoginHistorikDetalje) => void,
}

const useAktoerLoginHistorik = create<AktoerLoginHistorikStore>((set, get) => ({
  aktoerLogins: [],
  aktoerLoginDetaljer: [],
  setAktoerLogins: (aktoerLogins: StoreLoginHistorik) => set({ aktoerLogins: [...get().aktoerLogins, aktoerLogins] }),
  getAktoerLogin: async (aktoerId: string) => {
    const login = get().aktoerLogins.find(login => login.aktoerId === aktoerId);
    if (!login) {
      get().setAktoerLogins({ ...await getKontaktLoginHistory(aktoerId), aktoerId });
      return get().aktoerLogins.find(login => login.aktoerId === aktoerId);
    }
    return login;
  },
  setAktoerLoginDetalje: (aktoerLoginDetalje: StoreAktoerLoginHistorikDetalje) =>
    set({ aktoerLoginDetaljer: [...get().aktoerLoginDetaljer, aktoerLoginDetalje] }),
  getAktoerLoginDetalje: async (aktoerId: string, clientUuid: string) => {
    const loginDetalje = get().aktoerLoginDetaljer
    .find(detalje => detalje.aktoerId === aktoerId && detalje.clientUuid === clientUuid);
    if (!loginDetalje) {
      const tjeneste = (await get().getAktoerLogin(aktoerId))
      .aktoerLoginHistorik.find(historik => historik.clientUuid === clientUuid);
      const detaljer = {
        ...new LoginDetaljer(await getKontaktLoginHistoryDetails(aktoerId, clientUuid),
          clientUuid, tjeneste?.client ?? "Ukendt tjeneste"),
        aktoerId
      };
      get().setAktoerLoginDetalje(detaljer);
      return detaljer;
    }
    return loginDetalje;
  }
}));

export default useAktoerLoginHistorik;
