import { PATHS } from "@/constants/paths";
import { UserInfo } from "@/types/userinfo";
import { Navigate, useRouteLoaderData } from "react-router";
import { ReactNode } from "react";
import { FeatureToggle } from "@/types/featureToggle";

export default function FeatureToggledRoute({ children, featureToggle }: {
  children: ReactNode,
  featureToggle: keyof FeatureToggle
}) {
  const userinfo = useRouteLoaderData("main") as UserInfo;
  if (!userinfo.featureToggles[featureToggle]) {
    return <Navigate to={PATHS.FRONT} replace />;
  }
  return children;
}
