import { Link, useParams } from "react-router";
import LazyComponent from "@/components/lazy/LazyComponent";
import LoginDetaljeVisning from "@/components/loginhistorik/logindetaljer/LoginDetaljeVisning";
import LoginHistorikSkeletonLoader from "@/components/loginhistorik/LoginHistorikSkeletonLoader";
import LoginDetaljeTableHeader from "@/components/loginhistorik/logindetaljer/LoginDetaljeTableHeader";
import { PATHS } from "@/constants/paths";
import { useShallow } from "zustand/react/shallow";
import useAktoerLoginHistorik from "@/store/aktoerLoginHistorikStore";

export default function KontaktLoginDetaljeContainer() {
  const { aktoerId, clientUuid } = useParams();
  const { getAktoerLoginDetalje } = useAktoerLoginHistorik(useShallow(({ getAktoerLoginDetalje }) => ({ getAktoerLoginDetalje })));

  return <>
    <Link to={PATHS.KONTAKT_LOGIN_HISTORIK} className={"back-link"}>Tilbage til logins</Link>
    <h1>Logins for kontakter</h1>
    <p>En detaljeret oversigt over logins</p>
    <LazyComponent Component={LoginDetaljeVisning} promise={getAktoerLoginDetalje(aktoerId, clientUuid)}>
      <LoginHistorikSkeletonLoader amountOfColumns={2}>
        <LoginDetaljeTableHeader />
      </LoginHistorikSkeletonLoader>
    </LazyComponent></>;
}


