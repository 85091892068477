import { AktoerlisteDto } from "@/types/aktoerlisteDto";
import { uniqueByProperty } from "@/util/sort";

export const useAktoerKontakt = (aktoerListe: AktoerlisteDto) => {
  const hasKontakter = !!aktoerListe.aktoerer.find(aktoer => aktoer.elevForKontaktperson);
  const getKontakter = aktoerListe.aktoerer
  .flatMap(aktoer => aktoer.elevForKontaktperson)
  .filter(kontakt => kontakt);
  const getCurrentKontakt = (aktoerId: string) => {
    return getKontakter.find(kontakt => kontakt.aktoerUuid === aktoerId);
  };

  const getAggregratedKontakter = () => uniqueByProperty(getKontakter, "uniId");

  const primaerKontaktAktoers = getKontakter.filter(kontakt => kontakt.primaerKontaktAktoer);

  return {
    hasKontakter,
    getKontakter,
    getCurrentKontakt,
    getAggregratedKontakter,
    primaerKontaktAktoers
  };
};
