import { AktoerDto } from "@/types/aktoerDto";
import AktoerCard from "@/components/cards/AktoerCard";

type AktoerGruppeType = {
  aktoerer: AktoerDto[],
  overskrift: string;
}

export default function AktoerGruppe({ aktoerer, overskrift }: AktoerGruppeType) {
  return (
    <>
      <h2>{overskrift}</h2>
      <div className={"gridcontainer"}>
        {aktoerer.map((aktoer) => {
          return (
            <AktoerCard aktoer={aktoer} key={aktoer.aktoerUuid} />
          );
        })}
      </div>
    </>
  );
}
