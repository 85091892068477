export default function SolutionInfoMobile() {
  return (
    <div className="solution-info-mobile">
      <h3 className="sr-only">Børne og Undervisningsministeriet</h3>

      <p className="mb-2">
        <strong className="authority-name">Styrelsen for IT og Læring</strong>
      </p>

      <p>Support:<a href="tel:+4535878550">35 87 85 50</a></p>
    </div>
  );
}
