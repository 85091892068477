import { PATHS } from "@/constants/paths";
import useCsrfToken from "@/hooks/useCsrfToken";
export default function LoginButton() {
  const { inputField, csrfToken, setCsrfToken } = useCsrfToken();
  const submit = () => {
    setCsrfToken();
  }
  return (
    <div>
      <form action={PATHS.LOGIN_PATH} onSubmit={submit}>
        <input ref={inputField} name="_csrf" type="hidden" value={csrfToken ?? ""} />
        <div className={"form-group"}>
          <button type="submit" className={"button button-primary button-row"}>Log på</button>
        </div>
      </form>
    </div>
  );
}
