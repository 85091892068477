import { useRef } from "react";
import { getTokenFromCookie } from "@/util/cookieUtil";

export default function useCsrfToken() {
  const inputField = useRef<HTMLInputElement>(null);
  const csrfToken = getTokenFromCookie("XSRF-TOKEN=");
  const setCsrfToken = () => {
    const csrf = getTokenFromCookie("XSRF-TOKEN=");
    if (csrf && inputField.current) {
      inputField.current.value = csrf;
      inputField.current.dispatchEvent(new Event("change"));
    }
  }
  return {
    inputField,
    csrfToken,
    setCsrfToken,
  };
}
