import React, { ReactNode } from "react";
import Icon from "@/components/icons/Icon";

export type StatusBeskedType = {
  type: "info" | "success" | "warning" | "error",
  overskrift: string,
  children?: ReactNode,
}

export default function StatusBesked({ type, overskrift, children }: StatusBeskedType) {
  const { style, icon } = {
    info: { style: "alert alert-info", icon: <Icon icon={"#info"} label={"Information"} className={"alert-icon"} /> },
    warning: {
      style: "alert alert-warning",
      icon: <Icon icon={"#report-problem"} label={"Advarsel"} className={"alert-icon"} />
    },
    success: {
      style: "alert alert-success",
      icon: <Icon icon={"#check-circle"} label={"Success"} className={"alert-icon"} />
    },
    error: { style: "alert alert-error", icon: <Icon icon={"#highlight-off"} label={"Fejl"} className={"alert-icon"} /> }
  }[type];

  return <div className={style} role={"alert"}>
    {icon}
    <div className="alert-body">
      <h2 className="alert-heading">{overskrift}</h2>
      <p className="alert-text">{children}</p>
    </div>
  </div>;
}
