import { operations, paths } from "@/types/schema";
import { FeatureToggle } from "@/types/featureToggle";

export const PATHS = {
  FRONT: "/",
  MIN_SKOLE: "/min-skole",
  LOGIN_PATH: "/oauth2/authorization/mituniloginclient",
  LOGIND: "/logind",
  LOGOUT_PATH: "/logout",
  NOT_FOUND_PATH: "/error/not-found",
  SERVER_ERROR_PATH: "/error/server-error",
  LOGIN_HISTORIK: "/mine-logins",
  LOGIN_HISTORIK_DETALJER: "/mine-logins/:clientUuid",
  KONTAKT_LOGIN_HISTORIK: "/kontakt-logins/",
  KONTAKT_LOGIN_HISTORIK_AKTOER: "/kontakt-logins/:aktoerId",
  KONTAKT_LOGIN_HISTORIK_AKTOER_DETALJER: "/kontakt-logins/:aktoerId/:clientUuid",
  SKIFT_BRUGERNAVN: "/skift-brugernavn",
  SKIFT_BRUGERNAVN_BARN: "/skift-brugernavn/:aktoerId",
};

/*
* @currentMatch angiver hvilken route som skal angive om en sti er den aktive
* */
export type NavigationPaths = {
  path: string;
  text: string;
  description: string;
  hideOnIndex?: boolean;
  currentMatch?: string[];
  hideOnNoKontakt?: boolean;
  featureToggle?: keyof FeatureToggle
};

export const NAVIGATION_PATHS: NavigationPaths[] = [
  {
    path: PATHS.FRONT,
    text: "Oversigt",
    description: "",
    hideOnIndex: true,
  },
  {
    path: PATHS.MIN_SKOLE,
    text: "Min skole",
    description: "Her kan du se alle de institutioner du går på.",
  },
  {
    path: PATHS.LOGIN_HISTORIK,
    currentMatch: [PATHS.LOGIN_HISTORIK_DETALJER, PATHS.LOGIN_HISTORIK],
    text: "Mine logins",
    description: "Her kan du se dine seneste logins. " +
      "Du kan se hvilke tjenester du har logget ind på med dit Unilogin.",
  },
  {
    path: PATHS.KONTAKT_LOGIN_HISTORIK,
    currentMatch: [PATHS.KONTAKT_LOGIN_HISTORIK_AKTOER],
    text: "Logins for mine børn eller kontakter",
    description: "Her kan du se logins for dem du er forælder eller kontakt til. " +
      "Hvis du er kontakt eller forælder til flere, kan du vælge hvilke logins du ønsker at se.",
    hideOnNoKontakt: true,
  },
  {
    path: PATHS.SKIFT_BRUGERNAVN,
    currentMatch: [PATHS.SKIFT_BRUGERNAVN, PATHS.SKIFT_BRUGERNAVN_BARN],
    text: "Skift brugernavn",
    description: "Her kan du skifte brugernavn for dine børn eller kontakter",
    featureToggle: "skiftBrugernavn"
  },
];

export const API_PATHS: { [key in keyof operations]: keyof paths } = {
  userinfo: "/api/userinfo",
  getCSRFToken: "/api/csrftoken",
  getUserLoginHistory: "/api/user/mine-logins/",
  getKontaktLoginHistory: "/api/user/kontakt-logins/{aktoerId}",
  getUserLoginDetails: "/api/user/mine-login-detaljer/{clientUuid}",
  getKontaktLoginDetails: "/api/user/kontakt-login-details/{aktoerId}/{clientUuid}",
  updateUsername: "/api/username/update",
};
