import { LoginDetalje } from "@/types/loginHistorik";
import IngenLoginHistorik from "@/components/loginhistorik/IngenLoginHistorik";
import LoginDetaljeTableHeader from "@/components/loginhistorik/logindetaljer/LoginDetaljeTableHeader";
import { dateStringToDateWithTime } from "@/util/date";

export type LoginDetaljeTableType = {
  logins: LoginDetalje[];
  queryDate: string;
}

export default function LoginDetaljeTable({ logins, queryDate }: LoginDetaljeTableType) {
  return (
    logins?.length > 0 ?
      <div className={"table--responsive-scroll"}>
        <table className={"table table--selectable"}>
          <LoginDetaljeTableHeader />
          <tbody>
          {logins.map((login, i) =>
            <tr key={login.idpName + i}>
              <td>{login.idpName}</td>
              <td>{login.getFormattedDateTime()}</td>
            </tr>
          )}
          </tbody>
        </table>
        <span className={"form-hint"}>Udtræk foretaget {dateStringToDateWithTime(queryDate)}</span>

      </div> : <IngenLoginHistorik />
  );
}

