import { Link } from "react-router";
import { PATHS } from "@/constants/paths";
import Icon from "@/components/icons/Icon";
import MitUnilogin from "@/components/logo/MitUnilogin";

export default function SolutionInfo() {
  return (
    <div className="solution-info">
      <div className="solution-info-inner container">
        <div className="solution-heading">
          <Link to={PATHS.FRONT}>
            <MitUnilogin />
          </Link>
        </div>
        <div className="additional-info">
          <p>
            <strong className="authority-name">Styrelsen for IT og Læring</strong>
          </p>
          <p>
            Support: <a href="tel:+4535878550">35 87 85 50</a>
            <span className="d-block d-lg-inline-block d-print-none mt-2 mt-lg-0">
              <span className="d-none d-lg-inline-block mr-2">· </span>
              <a href={"https://www.stil.dk/support"} className="icon-link function-link">
                Kontakt
                <Icon icon={"#open-in-new"} />
              </a>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
