type PageButtonType = {
  page: number;
  onGoToPage: (page: number) => void;
  isActivePage: string;
};

export default function PageButton({ page, onGoToPage, isActivePage }: PageButtonType) {
  return (
    <li className={"pagination-item pagination-number"} key={"page" + page}>
      <button
        type="button"
        onClick={() => onGoToPage(page)}
        className={"button button-secondary" + isActivePage}
        aria-label={"Side " + page}
      >
        {page}
      </button>
    </li>
  );
}
