import { create } from "zustand";
import { LoginHistorik, LoginHistorikDetaljer } from "@/types/loginHistorik";
import { getUserLoginHistory, getUserLoginHistoryDetails } from "@/services/LoginHistoryService";
import { LoginDetaljer } from "@/model/loginDetalje";

type LoginHistorikStore = {
  login: LoginHistorik,
  loginDetaljer: LoginHistorikDetaljer[],
  getLogin: () => Promise<LoginHistorik>
  getLoginDetalje: (clientUuid: string) => Promise<LoginHistorikDetaljer>,
  setLogin: (login: LoginHistorik) => void,
  setLoginDetaljer: (loginDetaljer: LoginDetaljer[]) => void,
}

const useLoginHistorik = create<LoginHistorikStore>((set, get) => ({
  login: null,
  loginDetaljer: [],
  setLogin: (login: LoginHistorik) => set({ login }),
  setLoginDetaljer: (loginDetaljer: LoginHistorikDetaljer[]) => set({ loginDetaljer }),
  getLogin: async () => {
    const login = get().login;
    if (!login) {
      get().setLogin(await getUserLoginHistory());
      return get().login;
    }
    return login;
  },
  getLoginDetalje: async (clientUuid: string) => {
    const loginDetaljer = get().loginDetaljer
    .find(detaljer => detaljer.clientUuid === clientUuid);
    if (!loginDetaljer) {
      const logins = await get().getLogin();
      const tjenesteNavn = logins.aktoerLoginHistorik.find(historik => historik.clientUuid === clientUuid);
      const fetchedUserLoginDetails = await getUserLoginHistoryDetails(clientUuid);
      const loginDetalje = new LoginDetaljer(fetchedUserLoginDetails, clientUuid, tjenesteNavn.client ?? "Ukendt tjeneste");
      get().setLoginDetaljer([...get().loginDetaljer, loginDetalje]);
      return loginDetalje;
    }
    return loginDetaljer;
  }
}));

export default useLoginHistorik;
