export default function LoginDetaljeTableHeader() {
  return (
    <thead>
    <tr>
      <th scope={"col"}>Login</th>
      <th scope={"col"}>Logintidspunkt</th>
    </tr>
    </thead>
  );
}
