import SkiftBrugernavnBase from "@/components/skiftBrugernavn/SkiftBrugernavnBase";
import StatusBesked from "@/components/besked/StatusBesked";
import { Kontakt } from "@/types/kontakt";

type SkiftBrugernavnStepupType = {
  kontakt: Kontakt;
}

export default function SkiftBrugernavnStepup({kontakt}: SkiftBrugernavnStepupType) {
  return <SkiftBrugernavnBase onClick={() => null} skiftBrugernavnDisabled={true} kontakt={kontakt}>
    <StatusBesked type={"warning"}
                  overskrift={"Log på med to-faktor"}>
      Du er ikke logget ind med det nødvendige sikkerhedsniveau.
      Log af og på igen med to-faktor-godkendelse som f.eks. MitID.
    </StatusBesked>
  </SkiftBrugernavnBase>;
};
