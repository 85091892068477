import { Icons } from "@/types/icons";

export default function Icon({ icon, title, label, className }: {
  icon: Icons,
  title?: string,
  label?: string,
  className?: string;
}) {
  const clazz = ["icon-svg", className].join(" ");
  return (
    <svg className={clazz} focusable="false" aria-hidden="true" aria-label={label} xlinkTitle={title}>
      <use xlinkHref={icon}></use>
    </svg>
  );
}
