export const dateStringToDateWithTime = (date: string) => {
  const parsedDate = new Date(date);
  if (parsedDate.getDate()) {
    return new Intl.DateTimeFormat('da-DK', {
      dateStyle: 'full',
      timeStyle: 'short',
    }).format(parsedDate);
  }
  return "Kunne ikke indlæse datoen";
};
