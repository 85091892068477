import { PATHS } from "@/constants/paths";
import { redirect } from "react-router";
import { getTokenFromCookie } from "@/util/cookieUtil";
import useToastStore from "@/store/toastStore";
import { create400Toast } from "@/util/toasts";

export type RequestInitType = { method: "POST" | "PUT" | "GET"; body?: BodyInit }

export const ErrorStatus400 = {
  success: false,
  errorCode: {
    message: "Vi kunne ikke udføre den ønskede handling"
  }
}

export default async function baseFetch<T>(
  path: string,
  requestOptions?: RequestInitType,
): Promise<T & { trace?: string; message?: string; }> {
  let request = requestOptions ? buildRequestInit(requestOptions) : {};
  return fetch(path, request).then(async (response) => {
      if (response.ok) {
        return await response.json();
      }
      return Promise.resolve(handleResponseErrors(response.status));
    },
    async (error) => {
      throw new Error("Der skete en fejl " + error);
    });
}

export const buildRequestInit = (requestInit: RequestInitType): RequestInit => ({
  method: requestInit.method,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-XSRF-TOKEN": getTokenFromCookie("XSRF-TOKEN="),
  },
  body: requestInit.body,
});

const handleResponseErrors = (status: number) => {
  const { addToast } = useToastStore.getState();
  if (status === 403) {
    return redirect(PATHS.FRONT);
  }
  if (status === 400) {
    addToast(create400Toast());
    return ErrorStatus400;
  }
  throw new Error("Der skete en fejl");
};
