type PaginationAmountSelectorType = {
  readonly onSelectAmount: (amount: number) => void;
  readonly label: string;
  readonly totalEntries: number;
};

export default function PaginationAmountSelector({ onSelectAmount, label, totalEntries }: PaginationAmountSelectorType) {
  const parseSelectedAmount = (amount: string) => {
    return parseInt(amount);
  };
  const selectAmounts = [30, 50, 100, 500].filter((amount) => totalEntries > amount);
  return (
    <div>
      <label className={"mr-3"} htmlFor="paginationAmount">{label}</label>
      <select
        id="paginationAmount"
        className="form-select d-inline"
        onChange={({ currentTarget }) => onSelectAmount(parseSelectedAmount(currentTarget.value))}
      >
        {selectAmounts.map((amount) => (
          <option key={amount} value={amount}>
            {amount}
          </option>
        ))}
        <option value={totalEntries}>Alle</option>
      </select>
    </div>
  );
}
