import "../../assets/scss/index.scss";
import { useLoaderData } from "react-router";

import { UserInfo } from "@/types/userinfo";
import Layout from "@/views/layout/Layout";
import { useMemo } from "react";

export default function Main() {
  const user = useLoaderData() as UserInfo;
  const userinfo = useMemo(() => {
    return user;
  }, [user.isLoggedIn]);

  return <Layout userinfo={userinfo} />;
}
