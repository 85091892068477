import { UserInfo } from "@/types/userinfo";

export const defaultFeatureToggles: Pick<UserInfo, "featureToggles"> = {
  featureToggles: {
    skiftBrugernavn: false
  }
}

export const skiftBrugernavnFeatureEnabled: Pick<UserInfo, "featureToggles"> = {
  featureToggles: {
    skiftBrugernavn: true
  }
}
