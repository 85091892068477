import { create } from "zustand";

export type Toast = {
  type: "success" | "warning" | "info" | "error";
  header: string;
  body: string;
  timeout?: number;
  uuid: string;
};

type ToastStore = {
  toasts: Toast[];
  addToast: (toast: Toast) => void;
  removeToast: (id: string) => void;
};

const findAndRemoveToast = (toasts: Toast[], id: string) => {
  const index = toasts.findIndex((toast) => toast.uuid === id);
  if (index !== -1) {
    toasts.splice(index, 1);
  }
  return toasts;
};

const removeTimedOutToast = (id: string) => {
  useToastStore.getState().removeToast(id);
}

const useToastStore = create<ToastStore>()((set, get) => ({
  toasts: [],
  addToast: (toast) =>
    set(({ toasts }) => {
      if (toast.timeout) {
        setTimeout(() => removeTimedOutToast(toast.uuid), toast.timeout * 1000);
      }
      return {
        toasts: [...toasts, toast],
      };
    }),
  removeToast: (id) =>
    set(({ toasts }) => {
      return {
        toasts: findAndRemoveToast(toasts, id),
      };
    }),
}));
export default useToastStore;
