import StatusBesked from "@/components/besked/StatusBesked";
import { PATHS } from "@/constants/paths";
import Layout from "@/views/layout/Layout";
import { EMPTY_USERINFO } from "@/constants/errors";

export default function ServerFejl() {

  return (
    <Layout userinfo={EMPTY_USERINFO}>
      <StatusBesked type={"error"} overskrift={"Der skete en fejl"}>
        Der skete en fejl ved den ønskede handling. Prøv igen senere. <a href={PATHS.FRONT}>Gå til forsiden.</a>
      </StatusBesked>
    </Layout>
  );
}
