import AktoerGruppe from "@/components/aktoer/AktoerGruppe";
import { sortAktoerKontaktAlphabetically } from "@/util/sort";
import { AktoerDto } from "@/types/aktoerDto";

export default function Aktoerliste({ aktoerliste }: { readonly aktoerliste: AktoerDto[] }) {
  const elever = aktoerliste.filter(aktoer => aktoer.aktoerType === "Elev");
  const foraelderEllerKontakt = sortAktoerKontaktAlphabetically(
    aktoerliste.filter(aktoer => aktoer.aktoerType === "Forælder eller kontaktperson"));
  const medarbejdere = aktoerliste.filter(aktoer => aktoer.aktoerType === "Medarbejder");
  return (
    <>
      {!!elever.length && <AktoerGruppe aktoerer={elever} overskrift={"Elev"} />}
      {!!foraelderEllerKontakt.length &&
        <AktoerGruppe aktoerer={foraelderEllerKontakt}
                      overskrift={"Forælder eller kontaktperson"} />}
      {!!medarbejdere.length && <AktoerGruppe aktoerer={medarbejdere} overskrift={"Medarbejder"} />}
    </>
  );
}
