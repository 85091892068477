import LinkCard from "@/components/cards/LinkCard";
import HandWave from "@/components/emoji/HandWave";
import MitUnilogin from "@/components/logo/MitUnilogin";
import { NAVIGATION_PATHS } from "@/constants/paths";
import { useRouteLoaderData } from "react-router";
import { UserInfo } from "@/types/userinfo";
import { useAktoerKontakt } from "@/hooks/useAktoerKontakt";

export default function Oversigt() {
  const { aktoerOverblik, featureToggles } = useRouteLoaderData("main") as UserInfo;
  const { hasKontakter } = useAktoerKontakt(aktoerOverblik);

  const getLinkCards = () => {
    const paths = () => {
      return NAVIGATION_PATHS.filter((nav) => !nav.hideOnIndex).filter(nav => {
        if (nav.hideOnNoKontakt) {
          return !!hasKontakter;
        }
        if (nav.featureToggle !== undefined) {
          return featureToggles[nav.featureToggle];
        }
        return nav;
      });
    };
    return paths().map((path) => {
      return (
        <div className="col-12 col-sm-6 col-md-4 mb-6" key={path.path}>
          <LinkCard overskrift={path.text} beskrivelse={path.description} path={path.path} />
        </div>
      );
    });
  };

  return (
    <>
      <h1>
        Hej {aktoerOverblik.navn}, du er logget på <MitUnilogin /> <HandWave />
      </h1>
      <p role="heading" aria-level={2}>Her kan du se de oplysninger, som vi har om dig.</p>
      <div className="row">
        {getLinkCards()}
      </div>
    </>
  );
}
