import { AktoerLoginHistorik } from "@/types/loginHistorik";
import LoginHistorikTableHeader from "@/components/loginhistorik/LoginHistorikTableHeader";
import { dateStringToDateWithTime } from "@/util/date";
import IngenLoginHistorik from "@/components/loginhistorik/IngenLoginHistorik";
import Icon from "@/components/icons/Icon";

type LoginHistorikTableType = {
  onGoToDetails: (clientUuid: string) => void;
  queryDate: string;
  aktoerLoginHistorik: AktoerLoginHistorik[];
}

export default function LoginHistorikTable({ onGoToDetails, queryDate, aktoerLoginHistorik }: LoginHistorikTableType) {
  return (
    aktoerLoginHistorik?.length > 0 ?
      <div className={"table--responsive-scroll"}>
        <table className={"table table--selectable"}>
          <LoginHistorikTableHeader />
          <tbody>
          {aktoerLoginHistorik.map((login) =>
            <tr key={login.latestLogin}>
              <td>{login.client}</td>
              <td>{login.idp}</td>
              <td>{login.logins}</td>
              <td>{dateStringToDateWithTime(login.latestLogin)}</td>
              <td>
                <button type="button" className={"function-link"}
                        onClick={() => onGoToDetails(login.clientUuid)}>Se detaljer <Icon icon={"#arrow-right"} />
                </button>
              </td>
            </tr>
          )}
          </tbody>
        </table>
        <span className={"form-hint"}>Udtræk foretaget {dateStringToDateWithTime(queryDate)}</span>
      </div> : <IngenLoginHistorik />
  );
}
