import useToastStore from "@/store/toastStore";

export default function ToastContainer() {
  const { toasts, removeToast } = useToastStore();
  return (
    <div className="toast-container" aria-live="assertive" aria-atomic="false" aria-relevant="additions">
      {toasts.map((toast) => (
        <div className={`toast toast-${toast.type} show`} aria-atomic="true" key={toast.uuid}>
          <div className="toast-icon"></div>
          <div className="toast-message">
            <p>
              <strong>{toast.header}</strong>
            </p>
            <p>{toast.body}</p>
            <button className="toast-close" type="button" onClick={() => removeToast(toast.uuid)}>Luk</button>
          </div>
        </div>
      ))}
    </div>
  );
}
