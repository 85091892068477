import MitUnilogin from "@/components/logo/MitUnilogin";
import { ReactNode } from "react";

type ImageCardRightProps = {
  readonly children?: ReactNode;
  readonly imageAssetUrl: string;
  readonly altText: string;
}

export default function ImageRightCard({ children, imageAssetUrl, altText }: ImageCardRightProps) {
  return (
    <div className="card grid">
      <div className="grid-left">
        <div className="card-header">
          <h1>
            Velkommen til <MitUnilogin />
          </h1>
        </div>

        <div className="card-text">
          <p>
            Her på <MitUnilogin /> kan du logge på og se, hvilke skoler og fritidsinstitutioner du går på.
          </p>
          <p>
            Du har også mulighed for at se hvilke information vi har registreret på dig.
          </p>
          {children}
        </div>
      </div>
      <div className="grid-right">
        <img src={imageAssetUrl} alt={altText} />
      </div>
    </div>
  );
}
