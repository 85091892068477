import { Toast } from "@/store/toastStore";
import { UpdateUsernameDto } from "@/types/updateUserinfo";

const createToast = (toast: Pick<Toast, "body" | "header" | "timeout" | "type">): Toast => {
  return {
    ...toast,
    uuid: self.crypto.randomUUID(),
  };
};

export function createUpdateUsernameToastSuccess(updateUsernameDto: Pick<UpdateUsernameDto, "username">) {
  return createToast({
    header: "Brugernavn opdateret",
    body: `Brugernavnet er blevet opdateret. Det nye brugernavn er ${updateUsernameDto.username}`,
    type: "success",
    timeout: 5,
  });
};

export const createUpdateUsernameToastError = (updateUsernameDto: Pick<UpdateUsernameDto, "errorCode">) => {
  return createToast(
    {
      header: "Brugernavn ikke opdateret",
      body: `${updateUsernameDto.errorCode.message}. Brugernavnet blev ikke opdateret.`,
      type: "error"
    }
  );
};

export const create400Toast = () => {
  return createToast(
    {
      header: "Der skete en fejl",
      body: "Der skete en fejl med den ønskede handling. Prøv igen.",
      type: "error"
    }
  );
}
