import Nav from "@/components/nav/Nav";
import { UserInfo } from "@/types/userinfo";

export default function NavContainer({ userinfo }: { userinfo: UserInfo }) {
  return (
    <div className={"navigation-menu"}>
      <div className={"navigation-menu-inner container"}>
        <Nav className={"nav"} userinfo={userinfo} />
      </div>
    </div>
  );
}
