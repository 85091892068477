import { forwardRef } from "react";

type BaseDialogType = {
  header: string,
  body: string,
  submitCallback: () => void,
  submitButtonText: string,
  close: () => void,
}

const BaseDialog =
  forwardRef<HTMLDialogElement, BaseDialogType>(
    ({
       header,
       body,
       submitCallback,
       submitButtonText,
       close
     }: BaseDialogType, ref) => {
      return <dialog ref={ref}>
        <div className={"modal-header"}>
          <h2>{header}</h2>
        </div>
        <div className={"modal-content"}>
          <p>{body}</p>
        </div>
        <div className={"modal-footer"}>
          <button type={"button"} className={"button button-secondary"} onClick={close}>
            Annuller
          </button>
          <button type={"button"} className={"button button-primary"} onClick={submitCallback}>
            {submitButtonText}
          </button>
        </div>
      </dialog>;
    });

export default BaseDialog;

