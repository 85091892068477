import { PATHS } from "@/constants/paths";
import useCsrfToken from "@/hooks/useCsrfToken";

export default function LogoutButton() {
  const { inputField, csrfToken, setCsrfToken } = useCsrfToken();
  const submit = () => {
    setCsrfToken();
  }
  return (
    <form method="post" action={PATHS.LOGOUT_PATH} onSubmit={submit}>
      <input ref={inputField} name="_csrf" type="hidden" value={csrfToken ?? ""} />
      <button type="submit" className="button button-secondary d-print-none">
        Log af
      </button>
    </form>
  );
}
