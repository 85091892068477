import LoginHistorikTable from "@/components/loginhistorik/LoginHistorikTable";
import LoginHistorikSkeletonLoader from "@/components/loginhistorik/LoginHistorikSkeletonLoader";
import LoginHistorikTableHeader from "@/components/loginhistorik/LoginHistorikTableHeader";
import useLoginHistorik from "@/store/loginHistorikStore";
import LazyComponent from "@/components/lazy/LazyComponent";
import { useNavigate } from "react-router";
import { PATHS } from "@/constants/paths";
import { useShallow } from "zustand/react/shallow";

export default function LoginHistorik() {
  const { getLogin } = useLoginHistorik(useShallow(({ getLogin }) => ({ getLogin })));
  const navigate = useNavigate();
  const goToDetails = (clientUuid: string) => {
    navigate(PATHS.LOGIN_HISTORIK + "/" + clientUuid);
  };

  return <>
    <h1>Mine logins</h1>
    <p>Her kan du se en liste over de logins du har med dit UniLogin. Listen går 30 dage tilbage.</p>
    <p>Listen er sorteret efter dit seneste login.</p>
    <LazyComponent Component={LoginHistorikTable} promise={getLogin()} additionalProps={{ onGoToDetails: goToDetails }}>
      <LoginHistorikSkeletonLoader amountOfColumns={5}>
        <LoginHistorikTableHeader />
      </LoginHistorikSkeletonLoader>
    </LazyComponent>
  </>;
}
