import { Outlet, useParams } from "react-router";
import { useContext, useMemo } from "react";
import { UserContext } from "@/contexts/userContext";
import { useAktoerKontakt } from "@/hooks/useAktoerKontakt";
import KontaktListe from "@/components/loginhistorik/KontaktListe";

export default function KontaktLoginHistorikContainer() {
  const userinfo = useContext(UserContext);
  const { aktoerId } = useParams();
  const { getAggregratedKontakter, getCurrentKontakt } = useAktoerKontakt(userinfo.aktoerOverblik);
  const kontakt = useMemo(() =>
      getCurrentKontakt(aktoerId)
    , [aktoerId]);
  return (
    <>
      <h1>Logins for kontakter</h1>
      <KontaktListe kontakter={getAggregratedKontakter()} currentKontakt={kontakt} />
      {
        kontakt && <>
          <p>Her kan du en liste over de logins som {kontakt.fornavn} har foretaget med sit UniLogin. Listen går 30 dage
            tilbage.</p>
          <p>Listen er sorteret efter seneste login.</p></>
      }
      <Outlet />
    </>
  );
}
