import { Link, useParams } from "react-router";
import useLoginHistorik from "@/store/loginHistorikStore";
import LazyComponent from "@/components/lazy/LazyComponent";
import LoginDetaljeVisning from "@/components/loginhistorik/logindetaljer/LoginDetaljeVisning";
import LoginHistorikSkeletonLoader from "@/components/loginhistorik/LoginHistorikSkeletonLoader";
import LoginDetaljeTableHeader from "@/components/loginhistorik/logindetaljer/LoginDetaljeTableHeader";
import { PATHS } from "@/constants/paths";
import { useShallow } from "zustand/react/shallow";

export default function LoginDetaljeContainer() {
  const { clientUuid } = useParams();
  const { getLoginDetalje } = useLoginHistorik(useShallow(({ getLoginDetalje }) => ({ getLoginDetalje })));

  return <>
    <Link to={PATHS.LOGIN_HISTORIK} className={"back-link"}>Tilbage til logins</Link>
    <h1>Mine logins</h1>
    <p>En detaljeret oversigt over logins.</p>
    <LazyComponent Component={LoginDetaljeVisning} promise={getLoginDetalje(clientUuid)}>
      <LoginHistorikSkeletonLoader amountOfColumns={2}>
        <LoginDetaljeTableHeader />
      </LoginHistorikSkeletonLoader>
    </LazyComponent></>;
}


