import { ReactNode } from "react";

type PaginationButtonType = {
  children: ReactNode;
  showNext: boolean;
  showPrevious: boolean;
  onNextPage: () => void;
  onPreviousPage: () => void;
};

export default function PaginationNavButtons({
  children,
  showNext,
  showPrevious,
  onNextPage,
  onPreviousPage,
}: PaginationButtonType) {
  return (
    <div className="buttons">
      <button
        type="button"
        onClick={() => onPreviousPage()}
        className="button button-arrow button-previous"
        aria-label="Forrige side"
        key={"previousButton"}
        disabled={!showPrevious}
      >
         <svg className="icon-svg" focusable="false" aria-hidden="true"><use xlinkHref="#chevron-left"></use></svg>
        <span className="pagination-nav-link">Forrige</span>
      </button>

      {children}
      <button
        type="button"
        onClick={() => onNextPage()}
        className="button button-arrow button-next"
        aria-label="Næste side"
        key={"nextButton"}
        disabled={!showNext}
      >
        <span className="pagination-nav-link">Næste</span>
        <svg className="icon-svg" focusable="false" aria-hidden="true"><use xlinkHref="#chevron-right"></use></svg>
      </button>
    </div>
  );
}
